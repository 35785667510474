<script setup>
// TODO Better error handling
// Page data which we pass to slug
const props = defineProps({
  heroHeader: {
    type: Object,
    default: {
      heroHeight: "short",
      transparencyMaskColor: "#000000",
      transparencyMaskPercentage: "35%",
      content: {
        contentAlignment:  "center",
        titleComponent: {      
          titleColor: "white",
          heading: "h1"
        },
        subtitleComponent: {
          subtitleColor: "white"
        }
      }
    }
  },
  promoBanner: {
    type: Boolean,
    default: false
  } 
});
let defaultHeroHeader = null;

const {globalFeaturesLocal, setGlobalFeaturesLocal, globalFeaturesData} = useGlobalFeaturesData();

// Getting the data from the server cache first
// const {data} = await globalFeaturesLocal();
const {data: globalFeatures } = await globalFeaturesData();
defaultHeroHeader = globalFeatures.value.heroHeaderGlobals

let titleComponent = null, subtitleComponent = null
  
const showHeroHeader = (defaultHeroHeader !== undefined && defaultHeroHeader !== null);

let backgroundImage, style, mobileBackgroundImage, image,
  title, mobileTitle, subtitle, mobileSubtitle , titleColor, subtitleColor,
  transparencyMask, transparencyMaskVideo, ifRightAlign, gradientTitleColor, imgAlignment, primaryCTA,
  secondaryCTA, typography, heading = null;
let mobileStyleImage = '';
let contentAlignment = 'center';
let gradientTitle = false;
if (showHeroHeader) {
  if (props.heroHeader && props.heroHeader.content) {  
    contentAlignment = props.heroHeader.content.contentAlignment;
    typography = props.heroHeader.content.typography;

    if (!!props.heroHeader.content.titleComponent) {
      title = props.heroHeader.content.titleComponent.title ? props.heroHeader.content.titleComponent.title : defaultHeroHeader.title;
      mobileTitle = props.heroHeader.content.titleComponent.mobileTitle ? props.heroHeader.content.titleComponent.mobileTitle : title;  
      gradientTitleColor = props.heroHeader.content.titleComponent.gradientTitleColor;
      titleColor = props.heroHeader.content.titleComponent.titleColor;
      heading = props.heroHeader.content.titleComponent.heading;

      titleComponent = {
        title: title,
        mobileTitle: mobileTitle,
        gradientTitleColor: gradientTitleColor,
        titleColor: titleColor,
        heading: heading
      }
    }
   
    if (!!props.heroHeader.content.subtitleComponent) {
      subtitle = props.heroHeader.content.subtitleComponent.subtitle ? props.heroHeader.content.subtitleComponent.subtitle : defaultHeroHeader.subtitle ;
      mobileSubtitle = !props.heroHeader.content.subtitleComponent.mobileSubtitle ? subtitle : props.heroHeader.content.subtitleComponent.mobileSubtitle;
      subtitleColor = props.heroHeader.content.subtitleComponent.subtitleColor;

      subtitleComponent = {
        subtitle: subtitle,
        subtitleColor: subtitleColor,
        mobileSubtitle: mobileSubtitle
      }
    }

    if (props.heroHeader.content.primaryCTA) {
      primaryCTA = props.heroHeader.content.primaryCTA;
    }

    if (props.heroHeader.content.secondaryCTA) {
      secondaryCTA = props.heroHeader.content.secondaryCTA;
    }
  }

  if (defaultHeroHeader.desktopHeroImage || (props.heroHeader && props.heroHeader.desktopHeroImage)) {
    backgroundImage = props.heroHeader.desktopHeroImage ? props.heroHeader.desktopHeroImage.url : defaultHeroHeader.desktopHeroImage.url;
  }
  
  if (defaultHeroHeader.mobileHeroImage || (props.heroHeader && props.heroHeader.mobileHeroImage)) {
    mobileBackgroundImage =  props.heroHeader.mobileHeroImage && props.heroHeader.mobileHeroImage.heroImage ? props.heroHeader.mobileHeroImage.heroImage.url : defaultHeroHeader.mobileHeroImage.url;
    imgAlignment = props.heroHeader.mobileHeroImage? props.heroHeader.mobileHeroImage.imgAlignment : 'center';
    if (mobileBackgroundImage) {
      mobileStyleImage = `<style>@media (max-width: 991px) { #hero-header { background: url(${mobileBackgroundImage}) ${imgAlignment} / cover no-repeat;} }</style>`
    }
  }
  image = `<style> #hero-header { background: url(${backgroundImage}) center / cover no-repeat; }</style>`;
  transparencyMask = `<style> #hero-header:before { background: ${props.heroHeader.transparencyMaskColor}; opacity: ${props.heroHeader.transparencyMaskPercentage ? props.heroHeader.transparencyMaskPercentage : '35%' }}</style>`;  
  ifRightAlign = contentAlignment == "right" ? true : false;
  transparencyMaskVideo = `<style> .video-overlay { background: ${props.heroHeader.transparencyMaskColor}; opacity: ${props.heroHeader.transparencyMaskPercentage ? props.heroHeader.transparencyMaskPercentage : '35%' }}</style>`;  

}

// Tags array for long form
if (props.heroHeader.leadGenForm && props.heroHeader.tagsArray != null && props.heroHeader.tagsArray.length > 0) {
  props.heroHeader.leadGenForm.tagsArray = props.heroHeader.tagsArray;
}

const heroHeaderData = {
  contentAlignment: contentAlignment,
  typography: typography,
  ifRightAlign: ifRightAlign,
  titleComponent: titleComponent,
  subtitleComponent: subtitleComponent,
  primaryCTA: primaryCTA,
  secondaryCTA: secondaryCTA,
  locationSearch: props.heroHeader.locationSearch,
  leadGenForm: props.heroHeader.leadGenForm,
  toggleLeadGen: props.heroHeader.toggleLeadGenForm
}
</script>

<template>
  <div id="hero-header" class="w-100 position-relative" :class="heroHeader.heroHeight" v-if="showHeroHeader">
    <div v-if="heroHeader.backgroundVideo" class="hero-media">
      <!-- Overlay element for background color and opacity -->
      <div class="video-overlay" v-html="transparencyMaskVideo"></div>

      <!-- mobile video -->
      <video v-if="heroHeader.mobileBackgroundVideo" playsinline autoplay muted loop preload="metadata" 
        :poster="heroHeader.mobileBackgroundVideo.stillImage.url" id="bgvideo" 
        :class="'w-100 h-100' + (heroHeader.mobileBackgroundVideo ? ' d-lg-none' : '')" 
        :src="heroHeader.mobileBackgroundVideo.video.url"
        :alt="heroHeader.mobileBackgroundVideo.video.alternativeText"
        :title="heroHeader.mobileBackgroundVideo.video.caption">
      </video>
       <!-- Desktop video  -->
      <video playsinline autoplay muted loop id="bgvideo" 
        :class="'w-100 h-100' + (heroHeader.mobileBackgroundVideo ? ' d-none d-lg-block' : '')" 
        :poster="heroHeader.backgroundVideo.stillImage.url" 
        :src="heroHeader.backgroundVideo.video.url"
        :alt="heroHeader.backgroundVideo.video.alternativeText"
        :title="heroHeader.backgroundVideo.video.caption">
      </video>
      
    </div>
    <div v-else class="hero-media" title= "" v-html="image + mobileStyleImage + transparencyMask"></div>
    <template v-if="contentAlignment === 'center'">
      <LayoutCenteredHeroHeader
        :heroHeaderData="heroHeaderData" :promoBanner="promoBanner" />
    </template>
    <template v-else>
      <LayoutTwoSectionHeroHeader
        :heroHeaderData="heroHeaderData" :promoBanner="promoBanner" />
    </template>
    <!-- Lighbox mobile -->
    <UIVideoPlayButton v-if="heroHeader.mobileLightbox && heroHeader.mobileLightbox.lightboxVideo"
      :class="(heroHeader.mobileLightbox ? ' d-lg-none' : '')" 
      :poster = heroHeader.mobileLightbox.stillImage.url 
      :url = heroHeader.mobileLightbox.lightboxVideo.url 
      :alt = heroHeader.mobileLightbox.lightboxVideo.alternativeText
      :title = heroHeader.mobileLightbox.lightboxVideo.caption 
    />
    <!-- Lightbox desktop -->
    <UIVideoPlayButton v-if="heroHeader.desktopLightbox && heroHeader.desktopLightbox.lightboxVideo"
      :class="(heroHeader.mobileLightbox ? ' d-none d-lg-block' : '')" 
      :poster = heroHeader.desktopLightbox.stillImage.url 
      :url = heroHeader.desktopLightbox.lightboxVideo.url
      :alt =  heroHeader.desktopLightbox.lightboxVideo.alternativeText
      :title = heroHeader.desktopLightbox.lightboxVideo.caption
    />
  </div>
</template>

<style lang="scss">
#hero-header {
  
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hero-media {
    height: inherit;
  }

  video#bgvideo {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure the overlay is above the video (adjust if needed) */
  }

  &.short {
    min-height: 400px;
  }

  &.medium {
    min-height: 400px;

    @include media-breakpoint-up(lg) {
        min-height: 500px;
    }
  }

  &.tall {
      min-height: 550px;

      @include media-breakpoint-up(lg) {
          min-height: 600px;
      }
  }

  h1 {
    &.display1 {
      
      font-size: 2.75rem;

      @include media-breakpoint-between (sm, md) {
        font-size: 3.375rem;
      }
      @include media-breakpoint-up (lg) {
        font-size: 4rem;
      }
    }

    &.display2 {
      
      font-size: 2.5rem;

      @include media-breakpoint-between (sm, md) {
        font-size: 3rem;
      }
      @include media-breakpoint-up (lg) {
        font-size: 3.5rem;
      }
    }

    &.display3 {
      
      font-size: 2.25rem;

      @include media-breakpoint-between (sm, md) {
        font-size: 2.625rem;
      }
      @include media-breakpoint-up (lg) {
        font-size: 3rem;
      }
    }
  }

  .video-play-button {
    bottom: -($video-play-button-height-half);
    left: calc(50% - #{$video-play-button-width-half});
    margin-top: $video-play-button-width-half;
    z-index: 1;
  }
}
</style>
