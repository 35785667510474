<script setup>
const props = defineProps({
  url: String,
  poster: String
})

// Define your component's logic
const videoPlay = ref(null);

watchEffect(() => {
  if (process.client) {
    videoPlay.value = document.querySelectorAll('.video-play-button');
    videoPlay.value.forEach(function (playButton) {
      playButton.addEventListener('click', function () {
        let video = this.querySelector('video').cloneNode(); // Copy the <video> attached to this "button"

        let backdrop = document.createElement('div');
        backdrop.id = 'lightbox-backdrop';

        let contents = document.createElement('div');
        contents.id = 'lightbox-contents';

        let closeIcon = document.createElement('div');
        closeIcon.className = 'icon-close close d-none d-md-inline';

        contents.appendChild(video);
        contents.appendChild(closeIcon);

        backdrop.appendChild(contents);

        backdrop.addEventListener('click', function (e) {
          if (e.target.tagName.toLowerCase() === 'video') {
            return;
          }
          this.remove();
        });

        document.body.appendChild(backdrop);
        backdrop.style.display = 'block';
        video.play(); // Access DOM element directly to play video
      });
    });
  }
});
</script>

<template>
<div class="video-play-button icon-play">
  <video preload="none" controls :src="url" :poster="poster">
    {#- Fill in <source> elements if/when necessary. -#}
  </video>
</div>
</template>


<style lang="scss">
// $video-play-button-width: 88px;
// $video-play-button-height: $video-play-button-width;
// $video-play-button-width-half: calc($video-play-button-width / 2);
// $video-play-button-width-mobile: 60px;
// $video-play-button-width-mobile-half: calc($video-play-button-width-mobile / 2);
// $video-play-button-height: $video-play-button-width;
// $video-play-button-height-half: calc($video-play-button-height / 2);
// $video-play-button-height-mobile: $video-play-button-width-mobile;
// $video-play-button-height-mobile-half: calc($video-play-button-height-mobile / 2);

%video-play-button-shared {
  border-radius: 50%;
  box-shadow: 0 17px 31px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  //  z-index: $play-button-z-index;
  // transition: $animation-duration-default ease background-color;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-40%, -50%);
    background-image: linear-gradient(120deg, #1f7bb6 0%, #47ba9f 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
  }

  video {
    display: none;
  }
}

.video-play-button {
  @extend %video-play-button-shared;
  position: absolute; // Please provide x/y placement in your block, header, or other element to position this button.
  width: $video-play-button-width;
  height: $video-play-button-height;
  background-color: white;
}

#lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .75);
  z-index: 10000;
  display: none;

  #lightbox-contents {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    margin: 0 auto;

    .close {
      position: absolute;
      top: -3%;
      cursor: pointer;
      color: white;
    }

    video {
      max-height: calc(100vh - 5rem);
      max-width: calc(100% - 5rem);
    }
  }
}
</style>